import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import AppProvider from 'providers/AppProvider';
import { router } from 'routes';
import 'helpers/initFA';

// Redirect if the domain is not portal.newsgenie.ai
console.log(window.location.hostname);
if (
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== 'portal.newsgenie.ai'
) {
  window.location.href = 'https://portal.newsgenie.ai';
} else {
  const container = document.getElementById('main');
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </React.StrictMode>
  );
}
