import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Col,
  Spinner,
  Button,
  Form,
  Row,
  ButtonGroup
} from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';
import CustomPagination from 'components/utilities/CustomPagination';
import TextareaModal from 'components/utilities/TextareaModal';
import {
  selectBiasCheckerStatus,
  selectBiasCheckers,
  selectBiasCheckerError,
  getBiasCheckers
} from 'redux/biasCheckerSlice';
import { deleteBiasChecker } from 'helpers/requests'; // <-- Import your delete function

/** If needed, you might externalize these label maps into their own file or a config. */
const iterationLabelMap = {
  source: 'Source',
  iterationOne: 'Iteration One',
  iterationTwo: 'Iteration Two'
};

const modelLabelMap = {
  openai: 'OpenAI',
  anthropic: 'Anthropic',
  grok: 'Grok',
  gemini: 'Gemini'
};

const biasTable = {
  'framing bias': { threshold: 0.33 },
  'tone bias': { threshold: 0.33 },
  sensationalism: { threshold: 0.23 },
  'false balance': { threshold: 0.17 },
  'omission bias': { threshold: 0.23 },
  'narrative bias': { threshold: 0.33 },
  'linguistic bias': { threshold: 0.27 },
  'text-level context bias': { threshold: 0.27 },
  'cognitive bias': { threshold: 0.33 },
  'hate speech': { threshold: 0.1 },
  'racial bias': { threshold: 0.1 },
  'fake news': { threshold: 0.1 },
  'gender bias': { threshold: 0.17 },
  'political bias': { threshold: 0.33 },
  'negative sentiment': { threshold: 0.33 },
  classism: { threshold: 0.17 },
  stereotyping: { threshold: 0.17 },
  racism: { threshold: 0.1 },
  ageism: { threshold: 0.17 },
  'religious bias': { threshold: 0.17 },
  'confirmation bias': { threshold: 0.27 },
  'recency bias': { threshold: 0.33 },
  'authority bias': { threshold: 0.27 },
  'bandwagon effect': { threshold: 0.27 },
  'selection bias': { threshold: 0.23 },
  'survivorship bias': { threshold: 0.23 },
  'availability bias': { threshold: 0.27 },
  'anchoring bias': { threshold: 0.27 },
  'geographic bias': { threshold: 0.27 },
  'temporal bias': { threshold: 0.33 },
  'rhetoric bias': { threshold: 0.2 },
  'statement bias': { threshold: 0.17 },
  'ideology bias': { threshold: 0.23 },
  argumentation: { threshold: 0.2 },
  blaming: { threshold: 0.13 },
  demonization: { threshold: 0.1 },
  emotions: { threshold: 0.23 },
  fabulation: { threshold: 0.1 },
  'fear-mongering': { threshold: 0.13 },
  labeling: { threshold: 0.17 },
  relativization: { threshold: 0.2 }
};

const ALL_ITERATIONS = ['source', 'iterationOne', 'iterationTwo'];
const ALL_MODELS = ['openai', 'anthropic', 'grok', 'gemini'];

const Playground = () => {
  const dispatch = useDispatch();
  const biascheckers = useSelector(selectBiasCheckers);
  const status = useSelector(selectBiasCheckerStatus);
  const error = useSelector(selectBiasCheckerError);
  const { pages } = useSelector(state => state.biasChecker);

  const [currentPage, setCurrentPage] = useState(1);
  const [hideZeroValues, setHideZeroValues] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // For chart selections
  const [selectedIterations, setSelectedIterations] = useState([
    ...ALL_ITERATIONS
  ]);
  const [selectedModels, setSelectedModels] = useState([...ALL_MODELS]);

  // For bottom section text & transformation data
  const [selectedIteration, setSelectedIteration] = useState('iterationOne');
  const [selectedModel, setSelectedModel] = useState('openai');
  const [selectedMetric, setSelectedMetric] = useState('contentSimilarity');

  useEffect(() => {
    dispatch(getBiasCheckers({ page: currentPage, limit: 1 }));
  }, [dispatch, currentPage]);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  // === New Handlers for Delete and Exports ===
  const handleDelete = async id => {
    try {
      await deleteBiasChecker(id);
      // Re-fetch or otherwise update your state/store
      setCurrentPage(1);
      dispatch(getBiasCheckers({ page: 1, limit: 1 }));
    } catch (err) {
      console.error(err);
      // Optionally show an error message to the user
    }
  };

  const handleExportAsJSON = article => {
    const filename = `biasChecker_${article._id}.json`;
    const jsonStr = JSON.stringify(article, null, 2);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleExportAsCSV = article => {
    const filename = `biasChecker_${article._id}.csv`;

    // A very simplified CSV approach. For nested objects, consider using a CSV library.
    const fields = Object.keys(article);
    let csvData = fields.join(',') + '\n';

    // Convert values to strings, escape quotes
    const values = fields.map(f =>
      JSON.stringify(article[f] ?? '').replace(/"/g, '""')
    );
    csvData += values.join(',') + '\n';

    // Convert to Blob and create a download link
    const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };
  // === End of New Handlers ===

  // Toggle a single iteration in selectedIterations
  const handleIterationToggle = iteration => {
    if (selectedIterations.includes(iteration)) {
      setSelectedIterations(prev => prev.filter(i => i !== iteration));
    } else {
      setSelectedIterations(prev => [...prev, iteration]);
    }
  };

  // Toggle a single model in selectedModels
  const handleModelToggle = model => {
    if (selectedModels.includes(model)) {
      setSelectedModels(prev => prev.filter(m => m !== model));
    } else {
      setSelectedModels(prev => [...prev, model]);
    }
  };

  const getChartOptions = article => {
    if (!article || !article.biasData) return {};

    // We'll dynamically create a structure that includes only
    // the iterations and models selected by the user.
    const seriesMap = {};
    const biasTypes = Object.keys(biasTable).sort();

    for (let iteration of selectedIterations) {
      const iterationData = article.biasData[iteration];
      if (!iterationData) continue;

      for (let model of selectedModels) {
        const modelData = iterationData[model]?.bias_levels;
        if (!modelData) continue;

        // Use friendly labels:
        const iLabel = iterationLabelMap[iteration] || iteration;
        const mLabel = modelLabelMap[model] || model;
        const seriesName = `${iLabel} - ${mLabel}`;

        // Convert the object into an array in the order of biasTypes
        seriesMap[seriesName] = biasTypes.map(type => modelData[type] || 0);
      }
    }

    // Now we apply the hideZeroValues filter across all series data.
    let filteredBiasTypes = [...biasTypes];
    if (hideZeroValues) {
      filteredBiasTypes = biasTypes.filter((type, index) =>
        Object.values(seriesMap).some(dataArr => dataArr[index] > 0)
      );
    }

    // For each series, reorder data for the filtered bias types.
    const finalSeriesData = Object.entries(seriesMap).map(
      ([seriesName, originalData]) => {
        const newData = filteredBiasTypes.map(type => {
          const originalIndex = biasTypes.indexOf(type);
          return originalData[originalIndex] || 0;
        });
        return { name: seriesName, data: newData };
      }
    );

    // Build thresholds array for each filtered bias type
    const thresholds = filteredBiasTypes.map(
      type => biasTable[type]?.threshold || 0
    );

    // Some colors for each bar series (they will wrap if we have more than these).
    const colorPalette = [
      '#164863',
      '#603F26',
      '#427D9D',
      '#6C4E31',
      '#9BBEC8',
      '#FFDBB5',
      '#008080',
      '#FF6B6B',
      '#6D3AF9',
      '#BFD200',
      '#44546A'
    ];

    return {
      title: {
        text: 'Bias Score Comparison',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredBiasTypes,
        axisLabel: {
          interval: 0,
          rotate: 45
        }
      },
      yAxis: {
        type: 'value',
        name: 'Bias Score',
        min: 0,
        max: 1 // typically 0-1 range for these bias scores
      },
      legend: {
        data: [...finalSeriesData.map(item => item.name), 'Thresholds'],
        bottom: 0
      },
      series: [
        ...finalSeriesData.map((item, idx) => ({
          name: item.name,
          type: 'bar',
          data: item.data,
          itemStyle: {
            color: colorPalette[idx % colorPalette.length]
          }
        })),
        {
          name: 'Thresholds',
          type: 'line',
          data: thresholds,
          lineStyle: {
            type: 'dashed',
            color: '#AF1740'
          },
          symbol: 'circle',
          symbolSize: 6
        }
      ]
    };
  };

  // Force the chart to re-render by adding a `key` that depends on iteration/model selection.
  const chartKey = `${selectedIterations.join('-')}_${selectedModels.join(
    '-'
  )}_${hideZeroValues}`;

  // Helper to create the Gauge chart option for transformationData
  const getGaugeOption = (value, metricName) => {
    return {
      title: {
        text: `Transformation: ${metricName}`,
        left: 'center'
      },
      tooltip: {
        formatter: '{a} <br/>{b} : {c}%'
      },
      series: [
        {
          name: 'Transformation',
          type: 'gauge',
          detail: { formatter: '{value}%' },
          data: [{ value, name: metricName }],
          min: 0,
          max: 100
        }
      ]
    };
  };

  // Safely retrieve the text for the bottom section
  const getSelectedText = article => {
    if (!article?.textData?.[selectedIteration]?.[selectedModel]) {
      return 'No text data available for the selected iteration/model.';
    }
    return article.textData[selectedIteration][selectedModel];
  };

  // Safely retrieve the transformation metric for the gauge
  const getSelectedTransformationMetricValue = article => {
    if (!article?.transformationData?.[selectedIteration]?.[selectedModel])
      return 0;
    return (
      article.transformationData[selectedIteration][selectedModel][
        selectedMetric
      ] || 0
    );
  };

  return (
    <Row>
      <Col lg={12}>
        {/* Pagination Card */}
        <Card className="mb-4">
          <Card.Body>
            <div className="row justify-content-between align-items-center">
              <div className="col-auto">
                <Button
                  variant="outline-primary"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage <= 1}
                >
                  Previous
                </Button>
              </div>
              <div className="col-auto">
                <CustomPagination
                  pages={pages}
                  currentPage={currentPage}
                  handlePageChange={page => handlePageChange(page)}
                />
              </div>
              <div className="col-auto">
                <Button
                  variant="outline-primary"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage >= pages}
                >
                  Next
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* Textarea Modal Button */}
        <div className="mb-4 text-center">
          <Button variant="primary" onClick={() => setShowModal(true)}>
            Check Bias from Text
          </Button>
          <TextareaModal
            show={showModal}
            handleClose={() => setShowModal(false)}
          />
        </div>

        {/* Chart Selections */}
        <Card className="mb-4">
          <Card.Body>
            <Row className="mb-0">
              <Col>
                <p className="mb-0">Iterations:</p>
                <Form.Check
                  inline
                  type="checkbox"
                  label="Source"
                  checked={selectedIterations.includes('source')}
                  onChange={() => handleIterationToggle('source')}
                />
                <Form.Check
                  inline
                  type="checkbox"
                  label="Iteration One"
                  checked={selectedIterations.includes('iterationOne')}
                  onChange={() => handleIterationToggle('iterationOne')}
                />
                <Form.Check
                  inline
                  type="checkbox"
                  label="Iteration Two"
                  checked={selectedIterations.includes('iterationTwo')}
                  onChange={() => handleIterationToggle('iterationTwo')}
                />
              </Col>
              <Col>
                <p className="mb-0">Models:</p>
                {ALL_MODELS.map(model => (
                  <Form.Check
                    key={model}
                    inline
                    type="checkbox"
                    label={modelLabelMap[model]}
                    checked={selectedModels.includes(model)}
                    onChange={() => handleModelToggle(model)}
                  />
                ))}
              </Col>
            </Row>
            <Form.Check
              type="checkbox"
              label="Hide Zero Values"
              checked={hideZeroValues}
              onChange={e => setHideZeroValues(e.target.checked)}
              className="mt-3"
            />
          </Card.Body>
          <Card.Body className="overflow-hidden">
            <div className="container">
              {status === 'loading' ? (
                <div
                  style={{ height: '480px' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spinner animation="border" />
                </div>
              ) : status === 'failed' ? (
                <div>Error: {error}</div>
              ) : (
                <div>
                  {biascheckers.length > 0 && biascheckers[0].biasData ? (
                    <div className="row mb-4">
                      <Col>
                        <ReactECharts
                          key={chartKey} // <--- Force re-render
                          option={getChartOptions(biascheckers[0])}
                          style={{ height: '400px', width: '100%' }}
                        />
                      </Col>
                    </div>
                  ) : (
                    <p>No bias data available for the current article.</p>
                  )}
                </div>
              )}
            </div>
          </Card.Body>
        </Card>

        {/* Bottom Section: Compare Source Text & Generated Story */}
        <Card className="mb-4">
          <Card.Body className="overflow-hidden">
            {biascheckers.length === 0 ? (
              <p>No articles to display</p>
            ) : (
              <>
                {biascheckers.map(article => (
                  <Row className="mb-4" key={article._id}>
                    {/* Generated/Iteration Text & Transformation */}
                    <Col md={12} lg={12} className="d-flex mb-4">
                      <Card style={{ width: '100%' }}>
                        <Card.Header>
                          <Row className="align-items-center">
                            <Col>
                              <strong>Select Iteration & Model</strong>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <Form.Select
                                value={selectedIteration}
                                onChange={e =>
                                  setSelectedIteration(e.target.value)
                                }
                              >
                                <option value="iterationOne">
                                  Iteration One
                                </option>
                                <option value="iterationTwo">
                                  Iteration Two
                                </option>
                              </Form.Select>
                            </Col>
                            <Col>
                              <Form.Select
                                value={selectedModel}
                                onChange={e => setSelectedModel(e.target.value)}
                              >
                                <option value="openai">OpenAI</option>
                                <option value="anthropic">Anthropic</option>
                                <option value="grok">Grok</option>
                                <option value="gemini">Gemini</option>
                              </Form.Select>
                            </Col>
                          </Row>
                        </Card.Header>
                        <Card.Body>
                          <div className="mb-3">
                            <strong>Transformation Metrics:</strong>
                          </div>
                          {/* Button group to select which transformation metric to show on the Gauge */}
                          <ButtonGroup className="mb-3" size="sm">
                            <Button
                              variant={
                                selectedMetric === 'contentSimilarity'
                                  ? 'primary'
                                  : 'outline-primary'
                              }
                              onClick={() =>
                                setSelectedMetric('contentSimilarity')
                              }
                            >
                              Content Similarity
                            </Button>
                            <Button
                              variant={
                                selectedMetric === 'biasAdjustment'
                                  ? 'primary'
                                  : 'outline-primary'
                              }
                              onClick={() =>
                                setSelectedMetric('biasAdjustment')
                              }
                            >
                              Bias Adjustment
                            </Button>
                            <Button
                              variant={
                                selectedMetric === 'structuralChanges'
                                  ? 'primary'
                                  : 'outline-primary'
                              }
                              onClick={() =>
                                setSelectedMetric('structuralChanges')
                              }
                            >
                              Structural Changes
                            </Button>
                            <Button
                              variant={
                                selectedMetric === 'lexicalChanges'
                                  ? 'primary'
                                  : 'outline-primary'
                              }
                              onClick={() =>
                                setSelectedMetric('lexicalChanges')
                              }
                            >
                              Lexical Changes
                            </Button>
                            <Button
                              variant={
                                selectedMetric === 'weightedScore'
                                  ? 'primary'
                                  : 'outline-primary'
                              }
                              onClick={() => setSelectedMetric('weightedScore')}
                            >
                              Weighted Score
                            </Button>
                          </ButtonGroup>
                          {/* Show the gauge for the selected metric */}
                          <ReactECharts
                            option={getGaugeOption(
                              getSelectedTransformationMetricValue(article),
                              selectedMetric
                            )}
                            style={{ height: '400px', width: '100%' }}
                          />
                          <hr />
                          <Card.Title>Selected Story</Card.Title>
                          <Card.Text>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: getSelectedText(article)
                              }}
                            ></div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    {/* Original Source Text */}
                    <Col md={12} lg={12} className="d-flex mb-4">
                      <Card style={{ width: '100%' }}>
                        <Card.Header>Original Source Text</Card.Header>
                        <Card.Body className="d-flex flex-column">
                          <Card.Text className="flex-grow-1 text-dark">
                            <div>{article.sourceText}</div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    {/* Delete & Export Buttons */}
                    <Col md={12} className="d-flex justify-content-end">
                      <Button
                        variant="danger"
                        className="me-2"
                        onClick={() => handleDelete(article._id)}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="outline-secondary"
                        className="me-2"
                        onClick={() => handleExportAsJSON(article)}
                      >
                        Export as JSON
                      </Button>
                      <Button
                        variant="outline-secondary"
                        onClick={() => handleExportAsCSV(article)}
                      >
                        Export as CSV
                      </Button>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Playground;
